* {
    margin: 0;
    padding: 0;

    font-family: -apple-system, BlinkMacSystemFont, sans-serif, 'Segoe UI';
}

.greeting-header {
    font-size: 36px;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 5px;
    height: 40vh;
}

.version-footer {
    position: fixed;
    left: 10px;
    bottom: 10px;
}