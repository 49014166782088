.center {
    margin-top: -80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 2;
  }
  
  .navbar {
    min-width: 800px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid #777;
    background-color: #f3f3f3;
    border-radius: 8px;
  }
  
  li {
    float: left;
    border-style: solid;
    border: 1px black;
    cursor: pointer;
  }
  
  .right {
    float: right;
  }
  