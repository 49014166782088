.text {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;

    color: blue;
}

.small {
    background-color: rgb(100, 100, 100);
    color: white;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;

    border: none;
    border-radius: 4px;
}

.logo {
    cursor: pointer;
    mask-image: url('../assets/icons/logo-react.svg');
    padding: 30px;
    background-color: #61DBFB;
}

.logo:focus{
    background-color: #61DBFB;
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
    transition: transform 1.5s;
}

.logo:active {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0s;
}

.small:hover {
    background-color: black;
    cursor: pointer;
}

button {
    background-color: gray;
    outline: none;
}

button:active {
    border-style: outset;
}

button:focus {
    background-color: black;
    outline: none;
}